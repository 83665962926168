import React from 'react'
import PropTypes from 'prop-types'
import { StrapiImageSharpPropType } from '../propTypes'
import LocationImage from './LocationImage'
import LocationDetails from './LocationDetails'

const Location = ({ image, name, address, phoneNumber, fax, email }) =>
  <div className='location'>
    <LocationImage
      image={image}
      objectFit='cover'
    />
    <LocationDetails
      name={name}
      address={address}
      phoneNumber={phoneNumber}
      fax={fax}
      email={email}
    />
  </div>

export default Location

Location.propTypes = {
  image: StrapiImageSharpPropType,
  name: PropTypes.string,
  address: PropTypes.string,
  phoneNumber: PropTypes.string,
  fax: PropTypes.string,
  email: PropTypes.string
}

Location.defaultProps = {
  image: {},
  name: '',
  address: '',
  phoneNumber: '',
  fax: '',
  email: ''
}
