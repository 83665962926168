import React from 'react'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import { StrapiImagePropType } from '../propTypes'
import PropTypes from 'prop-types'

const AboutUsHeader = ({ header, description, image }) =>
  <>
    <SectionWrapper
      className='about-us-header'
      backgroundColor='teal'
    >
      <div className='about-us-header-text'>
        <div className='about-us-header-text__header'>{header}</div>
        <div className='about-us-header-text__description'>{description}</div>
      </div>
      <img
        className='about-us-header-image'
        src={image?.url}
        alt={image?.alternativeText}
      />
    </SectionWrapper>
    <div className='about-us-header-gradient-bar'/>
  </>

export default AboutUsHeader

AboutUsHeader.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string,
  image: StrapiImagePropType,
}

AboutUsHeader.defaultProps = {
  header: '',
  description: '',
  image: {},
}
