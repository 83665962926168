import React from 'react'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import Employee from './Employee'
import { EmployeePropType } from '../propTypes'
import PropTypes from 'prop-types'
import { Controller, Scene } from 'react-scrollmagic'

const Team = ({ employees }) =>
  <SectionWrapper className='team'>
    <div className='team__header'>
      <span>SenPrima</span>
      <span>Team</span>
    </div>
    <div className='team__bar' />
    <Controller>
      <Scene
        classToggle='employees-active'
        reverse={false}
        triggerHook='0.8'
      >
        <div className='employees'>
          {employees.map(employee => <Employee key={employee.name} {...employee} />)}
        </div>
      </Scene>
    </Controller>
  </SectionWrapper>

export default Team

Team.propTypes = {
  employees: PropTypes.arrayOf(EmployeePropType)
}

Team.defaultProps = {
  employees: []
}
