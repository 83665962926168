import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { StrapiImageSharpPropType } from '../propTypes'

const LocationImage = ({ image }) => {
  const gatsbyImageData = image?.localFile?.childImageSharp?.gatsbyImageData

  return <GatsbyImage
    className='location-image'
    alt={image.alternativeText}
    image={gatsbyImageData}
  />
}

export default LocationImage

LocationImage.propTypes = {
  image: StrapiImageSharpPropType,
}

LocationImage.defaultProps = {
  image: {},
}
