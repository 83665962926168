import React from 'react'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import { IconWithTextPropType } from '../propTypes'

const EmployeeIntroduction = ({ image, smallScreenImage, name, position, header, description }) => {
  const gatsbyImageData = image?.localFile?.childImageSharp?.gatsbyImageData
  const gatsbySmallScreenImageData = smallScreenImage?.localFile?.childImageSharp?.gatsbyImageData
  
  return <SectionWrapper className='employee-introduction'>
    <div className='employee-introduction-left-side'>
      <div className='employee-introduction-left-side__image'>
        <GatsbyImage
          alt={image?.alternativeText}
          image={gatsbyImageData}
        />
        <GatsbyImage
          alt={smallScreenImage?.alternativeText}
          image={gatsbySmallScreenImageData}
        />
      </div>
      <div className='employee-introduction-left-side__text'>
        <div>{name} </div>
        <div> {position}</div>
      </div>
    </div>
    <div className='employee-introduction-right-side'>
      <div className='employee-introduction-right-side__header'>
        <span>SenPrima</span>
        <span>Team</span>
      </div>
      <div className='employee-introduction-right-side__headline'>{header}</div>
      <div className='employee-introduction-right-side__description'>{description}</div>
    </div>
  </SectionWrapper>
}

export default EmployeeIntroduction

EmployeeIntroduction.propTypes = {
  employee: IconWithTextPropType,
  header: PropTypes.string,
  description: PropTypes.string
}

EmployeeIntroduction.defaultProps = {
  employee: {},
  header: '',
  description: ''
}
