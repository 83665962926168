import React from 'react'
import Layout from '../components/Layout/Layout'
import AboutUsHeader from '../components/AboutUsHeader/AboutUsHeader'
import EmployeeIntroduction from '../components/EmployeeIntroduction/EmployeeIntroduction'
import Team from '../components/Team/Team'
import Locations from '../components/Locations/Locations'
import AboutUsTiles from '../components/AboutUsTiles/AboutUsTiles'
import { graphql } from 'gatsby'

const AboutUsPage = ({ data }) => {
  const { description, strapiAboutUsPage: { header, employeeIntroduction, locations, tiles, team } } = data

  return <Layout description={description}>
    <AboutUsHeader {...header} />
    <AboutUsTiles {...tiles} />
    <EmployeeIntroduction {...employeeIntroduction} />
    <Team {...team} />
    <Locations {...locations} />
  </Layout>
}
export default AboutUsPage

export const query = graphql`
  query AboutUsPageQuery {
    strapiAboutUsPage {
      description
      ...aboutUsHeaderFragment
      ...employeeIntroductionFragment
      ...teamFragment
      ...locationsFragment
      ...aboutUsTilesFragment
    }
  }
 `
