import React from 'react'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import AboutUsTile from './AboutUsTile'
import { IconWithTextPropType } from '../propTypes'
import PropTypes from 'prop-types'

const AboutUsTiles = ({ header, tiles }) =>
  <SectionWrapper className='about-us-tiles-container'>
    <div className='about-us-tiles-container__header'>{header}</div>
    <div className='about-us-tiles-container__tiles'>
      {tiles.map(tile =>
        <AboutUsTile
          key={tile.header}
          {...tile}
        />
      )}
    </div>
  </SectionWrapper>

export default AboutUsTiles

AboutUsTiles.propTypes = {
  header: PropTypes.string,
  tiles: PropTypes.arrayOf(IconWithTextPropType)
}

AboutUsTiles.defaultProps = {
  header: '',
  tiles: []
}
