import React from 'react'
import { StrapiImagePropType } from '../propTypes'
import PropTypes from 'prop-types'

const AboutUsTile = ({ image, header, description }) =>
  <div className='about-us-tile'>
    <img
      src={image?.url}
      alt={image?.alternativeText}
      className='about-us-tile__image'
    />
    <div className='about-us-tile__header'>{header}</div>
    <div className='about-us-tile__description'>{description}</div>
  </div>

export default AboutUsTile

AboutUsTile.propTypes = {
  image: StrapiImagePropType,
  header: PropTypes.string,
  description: PropTypes.string
}

AboutUsTile.defaultProps = {
  image: {},
  header: '',
  description: ''
}
