import React from 'react'
import Paragraph from '../Paragraph/Paragraph'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const LocationDetails = ({ name, address, phoneNumber, fax, email }) =>
  <div className='location-details'>
    <Paragraph
      text={name}
      className='location-details__name'
    />
    <Paragraph
      text={address}
      className='location-details__address'
    />
    <div className='location-details__phone-number'>Tel: {phoneNumber}</div>
    <div className='location-details__fax'>Fax: {fax}</div>
    <div className='location-details__email'>E-Mail: {email}</div>
    <Link
      className='location-details__button'
      to='/kontaktformular/'
    >
      Kontakt
    </Link>
  </div>

export default LocationDetails

LocationDetails.propTypes = {
  name: PropTypes.string,
  address: PropTypes.string,
  phoneNumber: PropTypes.string,
  fax: PropTypes.string,
  email: PropTypes.string
}

LocationDetails.defaultProps = {
  name: '',
  address: '',
  phoneNumber: '',
  fax: '',
  email: ''
}
