import React from 'react'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import Location from './Location'
import PropTypes from 'prop-types'
import { LocationPropType } from '../propTypes'

const Locations = ({ header, locations }) =>
  <SectionWrapper
    className='locations-container'
    id='locations'
  >
    <div className='locations-container__header'>{header}</div>
    <div className='locations'>
      {locations.map(location => <Location key={location.name}{...location} />)}
    </div>
  </SectionWrapper>

export default Locations

Locations.propTypes = {
  header: PropTypes.string,
  locations: PropTypes.arrayOf(LocationPropType)
}

Locations.defaultProps = {
  header: '',
  locations: []
}
