import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import { StrapiImageSharpPropType } from '../propTypes'

const Employee = ({ image, name, position, description }) => {
  const gatsbyImageData = image?.localFile?.childImageSharp?.gatsbyImageData

  return <div className='employee'>
    <GatsbyImage
      image={gatsbyImageData}
      alt={image?.alternativeText}
      className='employee__image'
    />
    <div className='employee-details'>
      <div className='employee-details__name'>{name}</div>
      <div className='employee-details__position'>{position}</div>
      <div className='employee-details__description'>{description}</div>
    </div>
  </div>
}

export default Employee

Employee.propTypes = {
  image: StrapiImageSharpPropType,
  name: PropTypes.string,
  position: PropTypes.string,
  description: PropTypes.string
}

Employee.defaultProps = {
  image: {},
  name: '',
  position: '',
  description: ''
}
